<template>
  <div class="poster-setup-single container">
      <img :src="singlePosterImg" style="width: 100%;display: block;max-height: 500px"/>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
 props: {
    posterPayload: {
      type: Object
    }
 },
 data () {
    return {
        localDisplayLang: '',
        singlePosterImg: ''
    }
 },
 methods: {
    actGetSinglePoster () {
        if (this.posterPayload && this.posterPayload.displayType && this.posterPayload.poster) {
                let posters = this.posterPayload.poster[this.posterPayload.displayType];
                if (this.localDisplayLang == "eng") {
                    this.singlePosterImg = posters.en
                } else {
                    this.singlePosterImg = posters.ar
                }
            }
        }
    },
    created () {
        console.log("posterPayload", this.posterPayload)
        this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
        this.actGetSinglePoster();

        eventBus.$on("check-local-storage", data => {
        this.localDisplayLang = data;
        this.actGetSinglePoster();
        });
    }
}
</script>

<style scoped>
    .poster-setup-single  {
        text-align: center;
        /* width: 100%; */
    }
    .poster-setup-single img {
        width: 100%;
        display: block;
        max-height: 580px !important;
    }
</style>